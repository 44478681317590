import React, { useState, useEffect } from 'react';
import styles from './UserManagement.module.css';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', password: '', role: 'User', reports: [] });
  const [availableReports, setAvailableReports] = useState([]);
  const [isReportDropdownOpen, setIsReportDropdownOpen] = useState(false);
  const [changes, setChanges] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchAvailableReports();
  }, []);

  useEffect(() => {
    if (newUser.role === 'Admin') {
      setNewUser(prev => ({ ...prev, reports: ['all'] }));
    }
  }, [newUser.role]);

  const fetchUsers = async () => {
    try {
      const response = await fetch('api/users', {
        headers: {
          'X-User-Role': 'Admin'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchAvailableReports = async () => {
    try {
      const response = await fetch('api/reports', {
        headers: {
          'X-User-Role': 'Admin'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAvailableReports(data);
    } catch (error) {
      console.error("Error fetching available reports:", error);
    }
  };

  const handleCreateUser = () => {
    const newUserWithId = { ...newUser, id: Date.now().toString(), isNew: true };
    setUsers([...users, newUserWithId]);
    setChanges([...changes, { type: 'create', user: newUserWithId }]);
    setNewUser({ username: '', password: '', role: 'User', reports: [] });
  };

  const handleDeleteUser = (username) => {
    setUsers(users.filter(user => user.username !== username));
    setChanges([...changes, { type: 'delete', username }]);
  };

  const handleUpdateUser = (username, updates) => {
    setUsers(users.map(user => 
      user.username === username ? { ...user, ...updates } : user
    ));
    setChanges([...changes, { type: 'update', username, updates }]);
  };

  const handleReportChange = (reportId) => {
    if (newUser.role === 'Admin') return;
    
    setNewUser(prev => {
      if (reportId === 'all') {
        return { ...prev, reports: ['all'] };
      }
      const updatedReports = prev.reports.includes(reportId)
        ? prev.reports.filter(id => id !== reportId)
        : [...prev.reports.filter(id => id !== 'all'), reportId];
      return { ...prev, reports: updatedReports };
    });
  };

  const toggleReportDropdown = () => {
    if (newUser.role !== 'Admin') {
      setIsReportDropdownOpen(!isReportDropdownOpen);
    }
  };

  const saveChanges = async () => {
    for (const change of changes) {
      if (change.type === 'create') {
        await fetch('api/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-User-Role': 'Admin'
          },
          body: JSON.stringify(change.user)
        });
      } else if (change.type === 'delete') {
        await fetch('api/users', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-User-Role': 'Admin'
          },
          body: JSON.stringify({ username: change.username })
        });
      } else if (change.type === 'update') {
        await fetch('api/users', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-User-Role': 'Admin'
          },
          body: JSON.stringify({ username: change.username, updates: change.updates })
        });
      }
    }
    setChanges([]);
    fetchUsers();
  };

  const discardChanges = () => {
    setChanges([]);
    fetchUsers();
  };

  return (
    <div className={styles.userManagement}>
      <h2 className={styles.heading}>User Management</h2>
      <form onSubmit={(e) => { e.preventDefault(); handleCreateUser(); }} className={styles.form}>
        <input
          type="text"
          placeholder="Username"
          value={newUser.username}
          onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
          className={styles.input}
        />
        <input
          type="password"
          placeholder="Password"
          value={newUser.password}
          onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
          className={styles.input}
        />
        <select
          value={newUser.role}
          onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
          className={styles.select}
        >
          <option value="User">User</option>
          <option value="Admin">Admin</option>
        </select>
        <div className={styles.customSelect}>
          <div className={styles.selectTrigger} onClick={toggleReportDropdown}>
            {newUser.role === 'Admin' ? 'All Reports' : (newUser.reports.includes('all') ? 'All Reports' : `${newUser.reports.length} selected`)}
          </div>
          {isReportDropdownOpen && newUser.role !== 'Admin' && (
            <div className={styles.optionsList}>
              <div
                className={`${styles.option} ${newUser.reports.includes('all') ? styles.selected : ''}`}
                onClick={() => handleReportChange('all')}
              >
                All Reports
              </div>
              {availableReports.map(report => (
                <div
                  key={report.id}
                  className={`${styles.option} ${newUser.reports.includes(report.id) ? styles.selected : ''}`}
                  onClick={() => handleReportChange(report.id)}
                >
                  {report.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <button type="submit" className={styles.button}>Add User</button>
      </form>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Username</th>
            <th>Role</th>
            <th>Reports</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.username} className={user.isNew ? styles.newRow : ''}>
              <td>{user.username}</td>
              <td>{user.role}</td>
              <td>{user.reports.includes('all') ? 'All Reports' : user.reports.join(', ')}</td>
              <td>
                <button onClick={() => handleDeleteUser(user.username)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {changes.length > 0 && (
        <div className={styles.changeActions}>
          <button onClick={saveChanges} className={styles.button}>Save Changes</button>
          <button onClick={discardChanges} className={styles.button}>Discard Changes</button>
        </div>
      )}
    </div>
  );
};

export default UserManagement;