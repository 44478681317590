import React, { useState, useEffect, useCallback } from 'react';
import Login from './components/Login';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
import UserManagement from './components/UserManagement';
import DashboardManagement from './components/DashboardManagement';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userReports, setUserReports] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [selectedReport, setSelectedReport] = useState(null);
  const [dashboards, setDashboards] = useState([]);

  const fetchDashboards = useCallback(async () => {
    try {
      const response = await fetch('api/dashboards?visibleOnly=true', {
        headers: {
          'X-User-Role': userRole
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setDashboards(data);
    } catch (error) {
      console.error("Error fetching dashboards:", error);
    }
  }, [userRole]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setIsLoggedIn(true);
      setUserReports(storedUser.reports);
      setUserRole(storedUser.role);
      fetchDashboards();
    }
  }, [fetchDashboards]);

  const handleLoginSuccess = (reports, role) => {
    setIsLoggedIn(true);
    setUserReports(reports);
    setUserRole(role);
    fetchDashboards();
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserReports([]);
    setUserRole('');
    setSelectedReport(null);
    setDashboards([]);
    localStorage.removeItem('user');
  };

  const handleReportSelect = (reportName) => {
    setSelectedReport(reportName);
  };

  const handleDashboardUpdate = () => {
    fetchDashboards();
  };

  const renderContent = () => {
    if (selectedReport === 'userManagement' && userRole === 'Admin') {
      return <UserManagement />;
    } else if (selectedReport === 'dashboardManagement' && userRole === 'Admin') {
      return <DashboardManagement handleDashboardUpdate={handleDashboardUpdate} />;
    } else {
      return <Dashboard selectedReport={selectedReport} userReports={userReports} />;
    }
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <>
          <Navbar 
            onLogout={handleLogout} 
            onReportSelect={handleReportSelect} 
            userRole={userRole} 
            dashboards={dashboards}
            fetchDashboards={fetchDashboards}
          />
          {renderContent()}
        </>
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
}

export default App;