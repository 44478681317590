import React, { useEffect } from 'react';
import styles from './Navbar.module.css';

const Navbar = ({ onLogout, onReportSelect, userRole, dashboards, fetchDashboards }) => {
  useEffect(() => {
    fetchDashboards();
  }, [fetchDashboards]);

  const spreadsheets = [
    { name: "Active Users", id: "activeUsers" },
    { name: "VC In Cell Usage", id: "vcInCellUsage" }
  ];

  const tools = [
    { name: "Bid Builder", id: "bidBuilder" },
    { name: "Document Translator", id: "documentTranslator" }
  ];

  return (
    <nav className={styles.navbar}>
      <div className={styles.dropdown}>
        <button className={styles.dropbtn}>Dashboards</button>
        <div className={styles.dropdownContent}>
          {dashboards.map(dashboard => (
            <a href="#" key={dashboard.id} onClick={() => onReportSelect(dashboard.id)}>
              {dashboard.name}
            </a>
          ))}
        </div>
      </div>

      <div className={styles.dropdown}>
        <button className={styles.dropbtn}>Spreadsheets</button>
        <div className={styles.dropdownContent}>
          {spreadsheets.map(spreadsheet => (
            <a href="#" key={spreadsheet.id} onClick={() => onReportSelect(spreadsheet.id)}>
              {spreadsheet.name}
            </a>
          ))}
        </div>
      </div>

      <div className={styles.dropdown}>
        <button className={styles.dropbtn}>Tools</button>
        <div className={styles.dropdownContent}>
          {tools.map(tool => (
            <a href="#" key={tool.id} onClick={() => onReportSelect(tool.id)}>
              {tool.name}
            </a>
          ))}
        </div>
      </div>

      {userRole === 'Admin' && (
        <div className={styles.dropdown}>
          <button className={styles.dropbtn}>Admin Portal</button>
          <div className={styles.dropdownContent}>
            <a href="#" onClick={() => onReportSelect('userManagement')}>
              User Management
            </a>
            <a href="#" onClick={() => onReportSelect('dashboardManagement')}>
              Dashboard Management
            </a>
          </div>
        </div>
      )}

      <button onClick={onLogout} className={styles.logoutButton}>
        Logout
      </button>
    </nav>
  );
};

export default Navbar;