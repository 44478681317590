// src/components/ReportContainer.js
import React, { useState, useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import styles from './ReportContainer.module.css';

const ReportContainer = ({ reportName, userReports }) => {
  const [embedConfig, setEmbedConfig] = useState({
    type: 'report',
    embedUrl: '',
    accessToken: '',
    tokenType: models.TokenType.Embed,
    settings: {
      panes: {
        filters: {
          visible: false
        }
      }
    }
  });

  useEffect(() => {
    if (reportName) {
      loadReport(reportName);
    }
  }, [reportName, userReports]);

  const loadReport = (reportName) => {
    fetch(`api/report?reportName=${reportName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-User-Reports': JSON.stringify(userReports)
      }
    })
    .then(response => {
      if (!response.ok) {
        if (response.status === 403) {
          alert('You do not have permission to view this report.');
        } else {
          console.error('Network response was not ok');
        }
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data) {
        setEmbedConfig(prevConfig => ({
          ...prevConfig,
          embedUrl: data.embedUrl,
          accessToken: data.embedToken
        }));
      }
    })
    .catch(error => {
      console.error('Error during report load:', error);
      alert('Failed to load report');
    });
  };

  const eventHandlers = new Map([
    ['loaded', function () { console.log('Report loaded'); }],
    ['rendered', function () { console.log('Report rendered'); }],
    ['error', function (event) { console.log(event.detail); }]
  ]);

  return (
    <div className={styles.reportContainer}>
      <PowerBIEmbed
        embedConfig={embedConfig}
        eventHandlers={eventHandlers}
        cssClassName={styles.reportStyleClass}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />
    </div>
  );
};

export default ReportContainer;