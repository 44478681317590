import React, { useState } from 'react';
import styles from './Login.module.css'; // Assuming you have Login.module.css

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const response = await fetch('api/login', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Login failed:', response.status, errorText);
        throw new Error(`Login failed: ${response.status} ${errorText}`);
      }

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Received non-JSON response from server');
      }

      const data = await response.json();
      
      if (data && data.success) {
        // Store login information in localStorage
        localStorage.setItem('user', JSON.stringify({
          reports: data.reports,
          role: data.role,
          username: username
        }));
        onLoginSuccess(data.reports, data.role);
      } else {
        throw new Error('Login was not successful');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError(`Login failed: ${error.message}`);
    }
  };

  return (
      <div className={styles.loginContainer}>
        <form onSubmit={handleLogin} className={styles.formSignin}>
          <h2 className={styles.formSigninHeading}>Login</h2>
          {error && <div className={styles.errorMessage}>{error}</div>}
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className={styles.formControl}
            placeholder="Username"
            required
            autoFocus
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.formControl}
            placeholder="Password"
            required
          />
          <button className={styles.loginButton} type="submit">
            Login
          </button>
        </form>
      </div>
    );
};

export default Login;